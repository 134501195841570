import React from 'react'

const AuthLayout = React.lazy(() => import('@/pages/_layouts/AuthLayout'))
const Billing = React.lazy(() => import('@/pages/Billing'))
const CancelContracts = React.lazy(
  () => import('@/pages/_admin/CancelContracts'),
)
const Cases = React.lazy(() => import('@/pages/Cases'))
const ClaraLandingPage = React.lazy(() => import('@/pages/ClaraLandingPage'))
const CompaniesAdmins = React.lazy(
  () => import('@/pages/_admin/CompaniesAdmins'),
)
const CreateSingleCosts = React.lazy(
  () => import('@/pages/_admin/CreateSingleCosts'),
)
const Dashboard = React.lazy(() => import('@/pages/Dashboard'))
const DefaultLayout = React.lazy(() => import('@/pages/_layouts/DefaultLayout'))
const Demo = React.lazy(() => import('@/pages/Demo'))
const EditEmployees = React.lazy(() => import('@/pages/_admin/EditEmployees'))
const EmptyLayout = React.lazy(() => import('@/pages/_layouts/EmptyLayout'))
const EmployeeApps = React.lazy(() => import('@/pages/EmployeeApps'))
const Employees = React.lazy(() => import('@/pages/Employees'))
const EmployeesForProduct = React.lazy(
  () => import('@/pages/EmployeesForProduct'),
)
const Integrations = React.lazy(() => import('@/pages/Integrations'))
const IntegrationsLp = React.lazy(() => import('@/pages/IntegrationsLp'))
const InstanceDetails = React.lazy(() => import('../pages/InstanceDetails'))
const Instances = React.lazy(() => import('../pages/Instances'))
const LandingPage = React.lazy(() => import('@/pages/LandingPage'))
const ManageProducts = React.lazy(() => import('@/pages/_admin/ManageProducts'))
const ManageSavings = React.lazy(() => import('@/pages/_admin/ManageSavings'))
const ManageSoftwares = React.lazy(
  () => import('@/pages/_admin/ManageSoftwares'),
)
const MassOnboarding = React.lazy(() => import('@/pages/_admin/MassOnboarding'))
const NotFound = React.lazy(() => import('@/pages/NotFound'))
const PasswordRecover = React.lazy(() => import('@/pages/PasswordRecover'))
const PotentialSavingsCalculator = React.lazy(
  () => import('@/pages/PotentialSavingsCalculator'),
)
const PrivacyPolicy = React.lazy(() => import('@/pages/PrivacyPolicy'))
const ProductDetails = React.lazy(() => import('@/pages/ProductDetails'))
const Products = React.lazy(() => import('@/pages/Products'))
const ResetPassword = React.lazy(() => import('@/pages/ResetPassword'))
const RequestDetails = React.lazy(() => import('@/pages/RequestDetails'))
const RequestForProposal = React.lazy(
  () => import('@/pages/RequestForProposal'),
)
const RequestHistory = React.lazy(() => import('@/pages/RequestHistory'))
const Savings = React.lazy(() => import('@/pages/Savings'))
const Settings = React.lazy(() => import('@/pages/Settings'))
const SettingsProfile = React.lazy(() => import('@/pages/SettingsProfile'))
const SignIn = React.lazy(() => import('@/pages/SignIn'))
const SignUp = React.lazy(() => import('@/pages/SignUp'))
const TermsAndConditions = React.lazy(() => import('@/pages/TermsAndCondition'))
const DepartmentCosts = React.lazy(() => import('@/pages/DepartmentCosts'))

interface RouteComponents {
  [key: string]: React.ComponentType<React.PropsWithChildren<any>>
}

export const mapRouteComponents: RouteComponents = {
  AuthLayout,
  Billing,
  CancelContracts,
  Cases,
  ClaraLandingPage,
  CompaniesAdmins,
  CreateSingleCosts,
  Dashboard,
  DefaultLayout,
  Demo,
  DepartmentCosts,
  EditEmployees,
  EmptyLayout,
  EmployeeApps,
  Employees,
  EmployeesForProduct,
  Integrations,
  IntegrationsLp,
  InstanceDetails,
  Instances,
  LandingPage,
  ManageProducts,
  ManageSavings,
  ManageSoftwares,
  MassOnboarding,
  NotFound,
  PasswordRecover,
  PotentialSavingsCalculator,
  PrivacyPolicy,
  ProductDetails,
  Products,
  RequestDetails,
  RequestForProposal,
  RequestHistory,
  ResetPassword,
  Savings,
  Settings,
  SettingsProfile,
  SignIn,
  SignUp,
  TermsAndConditions,
}
